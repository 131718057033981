import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

import { NavigateBefore, Save } from '@material-ui/icons';
import { Box, Paper } from '@material-ui/core';

import { RemotePacsForm } from './RemotePacsForm';
import { Navbar } from '../../../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../../../componentsUI/SectionBar';
import { ScrollableContainer } from '../../../Common/ScrollableContainer';
import useStyles from '../../Edit/styled/HospitalFormMakeStyles';
import { UPDATE_REMOTE_PACS, GET_REMOTE_PACS_LIST } from '../../../../queries/RemotePacs/RemotePacs';
import { parseGraphqlErrors } from '../../../../utils/FormikUtils';
import { networkErrorParse } from '../../../../utils/ErrorGraphQLUtils';
import Can, { cannotI } from '../../../../casl/Can';
import { AlertUI, AlertWrapperUI } from '../../../../componentsUI/Alert';
import Loading from '../../../Common/Loading';

export const RemotePacsEdit = ({ remotePacs }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const [serverError, setServerError] = useState(null);
  const [lockSubmit, setLockSubmit] = useState(false);
  const form = useRef();
  const TYPE_C_FIND = 0;
  const TYPE_C_MOVE = 1;

  const updateErrorAction = (updateError) => {
    if (!updateError) {
      return;
    }
    if (updateError.includes('|')) {
      const [message, uuid] = updateError.split('|');
      setServerError({ level: 'info', message });
      setLockSubmit(true);
      toast(`${t('redirecting.to.existing.reference')}...`, { className: 'toast-warning' });
      setTimeout(() => navigate(`/hospital/detail/open-stow-rs/${uuid}`), 5000);
      return;
    }
    setLockSubmit(false);
    setServerError({ level: 'error', message: updateError });
  };

  const [updateRemotePacs, { loading: submitting }] = useMutation(UPDATE_REMOTE_PACS, {
    onCompleted: async () => {
      toast(t('remote.pacs.updated.successfully'), { className: 'toast-info' });
      setTimeout(() => navigate(`/hospital/settings/remote-pacs/view/${remotePacs.uuid}`), 3500);
    },
    onError: (e) => {
      const errorMsg = networkErrorParse(e).replace(/:/g, '.');
      updateErrorAction(errorMsg);
      setLockSubmit(false);
    },
    refetchQueries: [{
      query: GET_REMOTE_PACS_LIST,
      variables: {
        hospitalUuid: hospital.uuid,
      },
    }],
    awaitRefetchQueries: true,
  });

  const handleCancel = () => navigate('/hospital/settings/remote-pacs');
  const requestSubmit = () => form.current.submitForm();
  const handleSubmit = async (values, { setErrors }) => {
    setServerError(null);
    setLockSubmit(true);

    const cFindNodes = [{
      ip: values.cFindIp,
      port: values.cFindPort,
      aeTitle: values.cFindAeTitle,
      type: TYPE_C_FIND,
    }];

    const cMoveNodes = values.cMoveIp && values.cMovePort && values.cMoveAeTitle ? [{
      ip: values.cMoveIp,
      port: values.cMovePort,
      aeTitle: values.cMoveAeTitle,
      type: TYPE_C_MOVE,
    }] : [];

    if (values.cMoveIp || values.cMovePort || values.cMoveAeTitle) {
      ['cMoveIp', 'cMovePort', 'cMoveAeTitle'].forEach((field) => {
        if (!values[field]) {
          form.current.setFieldError(field, t('all.fields.are.required'));
          setLockSubmit(false);
        }
      });
      if (Object.keys(form.current.state.errors).length > 0) return;
    }

    try {
      const input = {
        uuid: remotePacs.uuid,
        serverName: values.serverName,
        searchType: values.searchType,
        dicomNodes: [...cFindNodes, ...cMoveNodes],
      };
      await updateRemotePacs({ variables: { input } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (Object.keys(formikErrors).length === 0 && formikErrors.constructor === Object) {
        setServerError({ level: 'error', message: networkErrorParse(errors) });
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const subtitle = t('add.remote.pacs.configuration');
  const buttons = [
    { name: 'go.back.to.list', icon: NavigateBefore, handleClick: handleCancel, disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'update', icon: Save, I: 'delete', data: hospital, handleClick: requestSubmit },
  ];
  const discardButton = { label: t('go.back.to.list'), action: handleCancel };

  return (
    <>
      <Navbar>
        <SectionBar
          title="hospital.settings.remote.pacs"
          subtitle={subtitle}
          items={buttons}
        />
      </Navbar>
      <Box className={classes.container}>
        {!hospital && <Loading />}
        {hospital && cannotI('manage', hospital) && (
          <AlertWrapperUI>
            <AlertUI severity="warning" title={t('non.authorized')}>
              {t('you.are.not.allowed.to.change.these.settings')}
            </AlertUI>
          </AlertWrapperUI>
        )}
        {hospital && hospital.menuOptionRemotePacs !== true && (
          <AlertWrapperUI>
            <AlertUI severity="warning" title={t('not.available')}>
              {t('this.configuration.is.not.enabled')}
            </AlertUI>
          </AlertWrapperUI>
        )}
        <Can I="manage" this={hospital}>
          <ScrollableContainer padding="1.25em 0 0">
            <Paper elevation={2} className={classes.wrapper}>
              <RemotePacsForm
                form={form}
                hospital={hospital}
                remotePacs={remotePacs}
                submitLabel={t('update')}
                handleSubmit={handleSubmit}
                discardButton={discardButton}
                submitting={submitting}
                lockSubmit={lockSubmit}
                serverError={serverError}
              />
            </Paper>
          </ScrollableContainer>
        </Can>
      </Box>
    </>
  );
};

export default RemotePacsEdit;
