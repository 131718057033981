import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Box, DialogActions, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Form } from '../../../Common/styled/Form';
import { AlertContainerUI, AlertUI } from '../../../../componentsUI/Alert';
import { ButtonUI } from '../../../../componentsUI/Button';
import { canI } from '../../../../casl/Can';
import Loading from '../../../Common/Loading';
import { capitalize } from '../../../../utils/StringUtils';
import { TextFieldUI } from '../../../../componentsUI/TextField';
import { TextFieldSelectUI } from '../../../../componentsUI/TextFieldSelect';
import { remotePacsSearchType } from '../../../../utils/RemotePacsUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  main: {
    marginBottom: 40,
  },
  group: {
    position: 'relative',
    border: '2px solid',
    borderRadius: 6,
    borderColor: `${theme.palette.primary.main}AF`,
    padding: 12,
    paddingBottom: 40,
    '& > .label': {
      position: 'absolute',
      top: -12,
      padding: 4,
      fontSize: '1.2rem',
      background: 'white',
      color: theme.palette.primary.main,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
    border: `1px solid ${theme.palette.primary.main}30`,
  },
  fieldset: {
    border: 0,
    margin: 0,
    padding: 0,
    '& .MuiGrid-container .MuiGrid-grid-xs-12:last-child': {
      height: 24,
      marginBottom: 16,
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
  },
  option: {
    '& .MuiFormControlLabel-label': {
      fontSize: '1.3rem',
      lineHeight: 1.15,
    },
  },
  notice: {
    marginTop: 8,
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
  },
}));

export const RemotePacsForm = ({
  form,
  hospital,
  remotePacs,
  submitLabel,
  discardButton,
  handleSubmit,
  serverError = null,
  submitting,
  lockSubmit = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [TYPE_C_FIND, TYPE_C_MOVE] = [0, 1];
  const error = serverError && serverError.message;

  const cFindNode = remotePacs && remotePacs.dicomNodes && remotePacs.dicomNodes.find((node) => node.type === TYPE_C_FIND);
  const cMoveNode = remotePacs && remotePacs.dicomNodes && remotePacs.dicomNodes.find((node) => node.type === TYPE_C_MOVE);

  const defaultSearchType = remotePacs && remotePacsSearchType.find((type) => type.value === remotePacs.searchType.toString());

  const initialValues = {
    serverName: remotePacs ? remotePacs.serverName : '',
    searchType: defaultSearchType ? defaultSearchType.value : '0',
    cFindIp: cFindNode ? cFindNode.ip : '',
    cFindPort: cFindNode ? cFindNode.port : '',
    cFindAeTitle: cFindNode ? cFindNode.aeTitle : '',
    cMoveIp: cMoveNode ? cMoveNode.ip : '',
    cMovePort: cMoveNode ? cMoveNode.port : '',
    cMoveAeTitle: cMoveNode ? cMoveNode.aeTitle : '',
  };

  const validationSchema = Yup.object().shape({
    serverName: Yup.string()
      .required(t('required.field')),
    searchType: Yup.string()
      .required(t('required.field')),
    cFindIp: Yup.string()
      .required(t('required.field')),
    // .matches(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/, t('not.a.valid.ipv4.address')),
    cFindPort: Yup.number()
      .required(t('required.field'))
      .typeError(t('input.value.must.be.a.number'))
      .test('port', t('value.should.not.exceed', { max: 65535 }), (val) => typeof val === 'undefined' || val < 65536)
      .test('port', t('value.should.not.be.less.than', { min: 1 }), (val) => typeof val === 'undefined' || val > 0),
    cFindAeTitle: Yup.string()
      .required(t('required.field')),
    cMoveIp: Yup.string(),
    // .matches(/^(\s*|((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4})$/, t('not.a.valid.ipv4.address')),
    cMovePort: Yup.number()
      .typeError(t('input.value.must.be.a.number'))
      .test('port', t('value.should.not.exceed', { max: 65535 }), (val) => typeof val === 'undefined' || val < 65536)
      .test('port', t('value.should.not.be.less.than', { min: 1 }), (val) => typeof val === 'undefined' || val > 0),
    cMoveAeTitle: Yup.string(),
  });

  const canEdit = hospital ? canI('edit', hospital) : true;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      ref={form}
    >
      {(props) => (
        <Form autoComplete="off" id="addRemotePacs">
          <fieldset className={classes.fieldset} disabled={submitting}>
            <Grid container spacing={2}>
              {(serverError || !canEdit) && (
                <Grid item xs={12} className={classes.main}>
                  {serverError && (
                    <Grid item xs={12}>
                      <AlertUI severity={serverError.level} title={capitalize(serverError.level)}>
                        {t(error)}
                      </AlertUI>
                    </Grid>
                  )}
                  {!canEdit && (
                    <AlertContainerUI>
                      <AlertUI severity="warning" title={t('unauthorized')}>
                        {t('you.are.not.allowed.to.perform.this.action')}
                      </AlertUI>
                    </AlertContainerUI>
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextFieldUI
                  name="serverName"
                  label={t('server.name')}
                  props={props}
                  classes={classes}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldSelectUI
                  name="searchType"
                  label={t('search.type')}
                  props={props}
                  classes={classes}
                  options={remotePacsSearchType}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={2} className={classes.group}>
                  <Box className="label">
                    {t('c.find.settings')}
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextFieldUI
                        name="cFindIp"
                        label={t('ip.or.hostname')}
                        props={props}
                        classes={classes}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldUI
                        name="cFindPort"
                        label={t('port')}
                        props={props}
                        classes={classes}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldUI
                        name="cFindAeTitle"
                        label={t('ae.title')}
                        props={props}
                        classes={classes}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={2} className={classes.group}>
                  <Box className="label">
                    {`${t('c.move.settings')} *`}
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextFieldUI
                        name="cMoveIp"
                        label={t('ip.or.hostname')}
                        props={props}
                        classes={classes}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldUI
                        name="cMovePort"
                        label={t('port')}
                        props={props}
                        classes={classes}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldUI
                        name="cMoveAeTitle"
                        label={t('ae.title')}
                        props={props}
                        classes={classes}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Typography component="div" className={classes.notice}>
                  {`* ${t('fill.only.if.different.from.c.find.settings')}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {submitting && <Loading />}
              </Grid>
            </Grid>

            <DialogActions>
              <ButtonUI
                variant="outlined"
                disabled={submitting || lockSubmit}
                color="primary"
                onClick={discardButton.action}
              >
                {discardButton.label}
              </ButtonUI>
              <ButtonUI
                variant="contained"
                disabled={submitting || lockSubmit}
                color="primary"
                type="submit"
              >
                {t(submitLabel)}
              </ButtonUI>
            </DialogActions>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
